@use '~/styles/font';
@use '~/styles/color';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/utils';

.gridContainer {
  display: grid;
  gap: 10px;
  padding: size.$half;

  .gridItem {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
}