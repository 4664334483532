@use '~/styles/size';
@use '~/styles/color';
@use '~/styles/mixins';
@use '~/styles/utils';

.container {
  right: 26px;
  top: calc(var(--y) - 72px);
  position: absolute;
  @include mixins.border-radius;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: utils.shadow($blur: utils.rem(5px));
  background-color: color.$background;
  z-index: 1;
  width: 500px;
}

.noteFrame {
  background-color: #ffffff;
  border: 1px solid color.$secondary;
  border-radius: 4px;
  width: 500px;
}

.addNewRow {
  padding: size.$half;
  cursor: pointer;
  width: fit-content;

  img {
    padding-right: size.$quarter;
    vertical-align: text-top;
  }

  &:hover {
    filter: brightness(0.5);
  }

  &[data-disabled='true'] {
    opacity: 0.5;
    pointer-events: none;
  }
}

.header {
  background-color: rgba(238, 238, 238, 0.8);
  border-radius: 4px 4px 0 0;
  min-height: 40px;
  color: #5f6b6a;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding-left: 8px;

  .buttonsContainer {
    margin-right: 0;
    margin-left: auto;
    display: flex;
    > * {
      margin: 8px 8px 8px 0;
    }
  }
}
