@use '~/styles/utils';
@use '~/styles/size';
@use '~/styles/mixins';
@use '~/styles/color';

.container {
  @include mixins.center('vertical');
  @include mixins.w-full;
  @include mixins.border;
  @include mixins.border-radius;
  background-color: color.$white;
  position: relative;
  height: size.$double;
  user-select: none;

  .selected {
    @include mixins.center('vertical');
    @include mixins.w-full;
    justify-content: space-between;
    padding-left: size.$half;
    cursor: pointer;

    .placeholder {
      color: color.$secondary;
    }
  }

  .options {
    @include mixins.border-radius;
    position: absolute;
    top: calc(100% + size.$quarter);
    left: 0;
    right: 0;
    // max-height: 200px;
    overflow: hidden;
    z-index: 999;

    &-container {
      height: 100%;
      background-color: color.$white;
      box-shadow: utils.shadow($y: utils.rem(2px), $blur: size.$quarter);
      padding: size.$half;
      overflow: hidden;
      margin-right: size.$n-quarter;
    }
  }
}
