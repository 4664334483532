@use '~/styles/size';

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  .inputRow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .unitsOfMeasurement {
    margin-left: size.$quarter;
  }

  .trashIcon {
    cursor: pointer;
    padding-bottom: size.$base;
    padding-right: size.$base;
    margin: 0;
  
    img {
      height: 20px;
      width: 20px;
      margin-left: 5px;
    }
  }

  .invisible {
    visibility: hidden;
  }
}

